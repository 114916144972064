module.exports = {
  __depends__: [
    require(227),
    require(162),
    require(217)
  ],
  __init__: [ 'resize', 'resizePreview', 'resizeHandles' ],
  resize: [ 'type', require(220) ],
  resizePreview: [ 'type', require(222) ],
  resizeHandles: [ 'type', require(221) ]
};
