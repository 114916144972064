module.exports = {
  __init__: [ 'selectionVisuals', 'selectionBehavior' ],
  __depends__: [
    require(170),
    require(209)
  ],
  selection: [ 'type', require(230) ],
  selectionVisuals: [ 'type', require(232) ],
  selectionBehavior: [ 'type', require(231) ]
};
