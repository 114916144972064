'use strict';

module.exports = {
  __init__: ['spaceToolPreview'],
  __depends__: [
    require(162),
    require(227),
    require(242),
    require(217)
  ],
  spaceTool: ['type', require(237)],
  spaceToolPreview: ['type', require(238) ]
};
