module.exports = {
  __depends__: [
    require(121),
    require(147),
    require(224),
    require(114)
  ],
  __init__: [
    'labelEditingProvider',
    'labelEditingPreview'
  ],
  labelEditingProvider: [ 'type', require(24) ],
  labelEditingPreview: [ 'type', require(23) ]
};
