module.exports = {
  __depends__: [
    require(213),
    require(157),
    require(240),
    require(176),
    require(168),
    require(248),
    require(20)
  ],
  __init__: [ 'paletteProvider' ],
  paletteProvider: [ 'type', require(71) ]
};
