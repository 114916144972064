'use strict';

var assign = require(431);

var BpmnModdle = require(100);

var packages = {
  bpmn: require(103),
  bpmndi: require(104),
  dc: require(105),
  di: require(106),
  bioc: require(102)
};

module.exports = function(additionalPackages, options) {
  return new BpmnModdle(assign({}, packages, additionalPackages), options);
};
