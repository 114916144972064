'use strict';

var inherits = require(289);

var assign = require(431),
    find = require(302);

var CommandInterceptor = require(119);

var getApproxIntersection = require(272).getApproxIntersection;

function isPointInsideBBox(bbox, point) {
  var x = point.x,
      y = point.y;

  return x >= bbox.x &&
    x <= bbox.x + bbox.width &&
    y >= bbox.y &&
    y <= bbox.y + bbox.height;
}

function copy(obj) {
  return assign({}, obj);
}

function getMid(bounds) {

  return {
    x: Math.round(bounds.x + bounds.width / 2),
    y: Math.round(bounds.y + bounds.height / 2)
  };
}

function DropOnFlow(eventBus, bpmnRules, modeling) {

  CommandInterceptor.call(this, eventBus);

  /**
   * Reconnect start / end of a connection after
   * dropping an element on a flow.
   */

  function insertShape(shape, targetFlow, position) {
    var waypoints = targetFlow.waypoints,
        waypointsBefore, waypointsAfter, dockingPoint, source, target, reconnected;

    var intersection = getApproxIntersection(waypoints, position);

    if (intersection) {
      waypointsBefore = waypoints.slice(0, intersection.index);
      waypointsAfter = waypoints.slice(intersection.index + (intersection.bendpoint ? 1 : 0));

      dockingPoint = intersection.bendpoint ? waypoints[intersection.index] : position;

      // if last waypointBefore is inside shape's bounds, ignore docking point
      if (!isPointInsideBBox(shape, waypointsBefore[waypointsBefore.length-1])) {
        waypointsBefore.push(copy(dockingPoint));
      }

      // if first waypointAfter is inside shape's bounds, ignore docking point
      if (!isPointInsideBBox(shape, waypointsAfter[0])) {
        waypointsAfter.unshift(copy(dockingPoint));
      }
    }

    source = targetFlow.source;
    target = targetFlow.target;

    if (bpmnRules.canConnect(source, shape, targetFlow)) {
      // reconnect source -> inserted shape
      modeling.reconnectEnd(targetFlow, shape, waypointsBefore || position);

      reconnected = true;
    }

    if (bpmnRules.canConnect(shape, target, targetFlow)) {

      if (!reconnected) {
        // reconnect inserted shape -> end
        modeling.reconnectStart(targetFlow, shape, waypointsAfter || position);
      } else {
        modeling.connect(shape, target, { type: targetFlow.type, waypoints: waypointsAfter });
      }
    }
  }

  this.preExecute('elements.move', function(context) {

    var newParent = context.newParent,
        shapes = context.shapes,
        delta = context.delta,
        shape = shapes[0];

    if (!shape || !newParent) {
      return;
    }

    // if the new parent is a connection,
    // change it to the new parent's parent
    if (newParent && newParent.waypoints) {
      context.newParent = newParent = newParent.parent;
    }

    var shapeMid = getMid(shape);
    var newShapeMid = {
      x: shapeMid.x + delta.x,
      y: shapeMid.y + delta.y
    };

    // find a connection which intersects with the
    // element's mid point
    var connection = find(newParent.children, function(element) {
      var canInsert = bpmnRules.canInsert(shapes, element);

      return canInsert && getApproxIntersection(element.waypoints, newShapeMid);
    });

    if (connection) {
      context.targetFlow = connection;
      context.position = newShapeMid;
    }

  }, true);

  this.postExecuted('elements.move', function(context) {

    var shapes = context.shapes,
        targetFlow = context.targetFlow,
        position = context.position;

    if (targetFlow) {
      insertShape(shapes[0], targetFlow, position);
    }

  }, true);

  this.preExecute('shape.create', function(context) {

    var parent = context.parent,
        shape = context.shape;

    if (bpmnRules.canInsert(shape, parent)) {
      context.targetFlow = parent;
      context.parent = parent.parent;
    }
  }, true);


  this.postExecute('shape.create', function(context) {

    var shape = context.shape,
        targetFlow = context.targetFlow,
        position = context.position;

    if (targetFlow) {
      insertShape(shape, targetFlow, position);
    }
  }, true);
}

inherits(DropOnFlow, CommandInterceptor);

DropOnFlow.$inject = [ 'eventBus', 'bpmnRules', 'modeling' ];

module.exports = DropOnFlow;
