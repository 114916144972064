module.exports = {
  __init__: [ 'modeling', 'bpmnUpdater' ],
  __depends__: [
    require(53),
    require(27),
    require(82),
    require(70),
    require(80),
    require(121),
    require(244),
    require(174),
    require(135),
    require(233),
    require(147),
    require(240)
  ],
  bpmnFactory: [ 'type', require(28) ],
  bpmnUpdater: [ 'type', require(30) ],
  elementFactory: [ 'type', require(31) ],
  modeling: [ 'type', require(32) ],
  layouter: [ 'type', require(29) ],
  connectionDocking: [ 'type', require(251) ]
};
