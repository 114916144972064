module.exports = {
  __depends__: [
    require(170),
    require(233),
    require(209),
    require(227),
    require(162),
    require(217)
  ],
  __init__: [ 'move', 'movePreview' ],
  move: [ 'type', require(204) ],
  movePreview: [ 'type', require(205) ]
};
