module.exports = {
  __depends__: [
    require(114),
    require(153),
    require(233),
    require(151),
    require(157),
    require(74)
  ],
  __init__: [ 'contextPadProvider' ],
  contextPadProvider: [ 'type', require(11) ]
};